import React from 'react';
import { SectionTitle } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { Link, withPrefix } from 'gatsby';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';


import css from './style.module.scss';

export const Images = ({ paths }) => (
  <div className={css.images}>
    <ThemeToggler>
      {({ theme, toggleTheme }) => (

        <div className={css.imagesRow}>
          {paths.map(i => (
            <img key={i} loading="lazy" src={withPrefix(`/homepage/${i}${theme === 'dark' ? '.dark' : '.light'}.jpg`)} alt={i} className={css.image} />
          ))}
          <div className={css.space} />

        </div>
      )}
    </ThemeToggler>
  </div>
);

export default Images;
