import React from 'react';

import { Text } from 'components/ui/Typography';
import css from './style.module.scss';

export const Buttons = ({ children, style, bottomText }) => (
  <div className={css.buttonsContainer} style={style}>
    <div className={css.buttons}>{children}</div>
    {bottomText && <Text>{bottomText}</Text>}
  </div>
);

export default Buttons;
