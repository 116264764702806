import React from 'react';
import Button from 'components/ui/Button';
import InviteButton from 'components/ui/InviteButton';
import Section from 'components/ui/Section';
import Buttons from 'components/ui/Buttons';
import Images from 'components/ui/Images';
import HeroBar from 'components/ui/HeroBar';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { Text } from 'components/ui/Typography';

const IndexPage = () => (
  <Layout>
    <SEO title="Metrical" />
    <Section
      title={() => (
        <span>
          A
          {' '}
          <b>privacy-first</b>
          {' '}
web analytics tool for everyone.
        </span>
      )}
    >
      <Text big>
      Finally a web analytics tool that is easy to use, privacy oriented and
      where you don't have to have a marketing degree to know how to use it.
      </Text>
    </Section>
    <Buttons
      // bottomText=" * We're still in Beta, but we'll invite you as soon as possible."
      style={{ marginTop: '-32px', marginBottom: '64px' }}
    >
      <Button
        style={{ marginRight: '32px' }}
        external
        secondary
        track="visit_register"
        to="https://app.metrical.xyz/register"
      >
Register now for free
      </Button>
      <Button
        link
        secondary
        external
        target="_blank"
        track="visit_demo"
        to="https://app.metrical.xyz/demo"
      >
        See a demo
      </Button>
    </Buttons>

    <Images paths={['homepage.1', 'details.1']} />

    <Section title="Simple and useful metrics, easy to understand">
      <Text big>
        <b>Metrical</b>
        {' '}
presents only the data that you will need in your reports
      and to know your audience.
        <br />
        {' '}
There aren’t 30+ different metrics that will confuse you, just 5 -
        {' '}
        <b>pages, sources, country, language and type of device</b>
.

      </Text>
    </Section>

    <Section title="We don't track your audience">
      <Text big>
        <b>We don’t use cookies</b>
        {' '}
or other ways to keep track of your visitors.
      So you can say Goodbye to that pesky cookie banners.
        {' '}
        <br />
        <br />
      Also, we don’t collect any personal data (like IP addresses) on our
      servers.

      </Text>
    </Section>

    <Images paths={['details.2', 'details.3']} />
    <Section title="Share your data with your colleagues, or your friends 👩‍🦰👨👩🏾">
      <Text big>
      You can share your websites' data with as many colleagues as you need.
        <br />
      Don't have any friends? Now you have us. We always want to make new
      friends.
      </Text>
    </Section>

    <Section title="You just need to paste 4 lines of code">
      <Text big>
      How to start using
        {' '}
        <b>Metrical</b>
        {' '}
in 3 simple steps:
      </Text>
      <ol>
        <li>Create a new app;</li>
        <li>Paste 4 lines of code at the end of your website;</li>
        <li>
          Did we say 3 Steps? We mean 2, sorry. You are already up and running!
          ✨
        </li>
      </ol>
    </Section>

    <Section title="Ready for custom events, too ">
      <Text big>
      Need to track how many users visited a page or signed up?
        {' '}
        <b>Metrical</b>
        {' '}
      does that too. You can setup custom events to track.
        <br />
        <br />
      Every event is
      associated with a visit, so you can also filter your events by metrics.
      </Text>
    </Section>
    <HeroBar>
      Still not sure?
      {'  '}
      <br />
      <Button
        link
        external
        to="https://app.metrical.xyz/demo"
        track="visit_demo"
      >
        Try our demo
      </Button>
    </HeroBar>

    <Section
      upTitle="Yeah, everything seems great, but..."
      title="How much will Metrical cost?"
    >
      <Text big>
      Just all the other parts of
        {' '}
        <b>Metrical</b>
, we wanted to keep things
      simple, so we offer just one plan with two prices:
        {' '}
        <b>7$/Month</b>
        {' '}
or
        {' '}
        <b>50$/Year</b>
.
        <br />
        <br />
      Everything is already included:
      </Text>
      <ul>
        <li>Unlimited websites</li>
        <li>50.000 pageviews every month*</li>
        <li>Unlimited events</li>
        <li>Share websites with whomever you want</li>
        <li>Download all the data as CSV</li>
      </ul>
      <Text>
       Join now and try Metrical for 2 weeks totally free!
      </Text>
      <Buttons>
        <Button external to="https://app.metrical.xyz/register" track="visit_register">
          Register now
        </Button>
      </Buttons>
      <small>
        * If you happen to exceed this limit we will contact you and define a
        new pricing, together.
      </small>
    </Section>
    {/* <Section
      upTitle="Pricing"
      title="Free during Beta ✨"
    >
      <Text big>

      We are currently in Beta testing, and you can register for free and have up to 2 websites and 50.000 visits every month.
        <br />
      If you want more, you can upgrade to
        {' '}
        <b>7$/Month</b>
        {' '}
or
        {' '}
        <b>50$/Year</b>
.

        <br />
        <br />
      Everything is already included:
      </Text>
      <ul>
        <li>Unlimited websites</li>
        <li>50.000 pageviews every month*</li>
        <li>Unlimited events</li>
        <li>Share websites with whomever you want</li>
        <li>Download all the data as CSV</li>
      </ul>
      <Buttons>
        <InviteButton external to="https://app.metrical.xyz/register" track="visit_register">
          Register now
        </InviteButton>
      </Buttons>
    </Section> */}
  </Layout>
);

export default IndexPage;
